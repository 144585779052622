//$grid-float-breakpoint: 10000px;
// Body
$body-bg: #fdd311;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #2777d1;
$brand-info: #8eb4cb;
$brand-success: #14ad59;
$brand-warning: #f4ea3d;
$brand-danger: #dc5a2b;

// Typography
$font-family-sans-serif: "Noto Sans", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #151518;

$headings-font-family: "Montserrat", sans-serif;
$headings-font-weight: 700;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Images
$thumbnail-bg: #ddd;

// Accurate colors
$accurate-yellow: #fdd311;
