
// Fonts
@import url(https://fonts.googleapis.com/css?family=Montserrat|Noto+Sans);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

//@import "parallax";

html,
body {
  height: 100%;
}

body {
  font-weight: 400;
}

.btn {
  font-family: $headings-font-family;
}

.navbar-toggle {
  display: inline-block;
  float: left;

  @media (min-width: 992px) {
    margin-top: 13px;
    margin-bottom: 13px;
    border-width: 2px;
    padding: 14px 15px;

    .icon-bar {
      height: 3px;
    }
  }
}

.navbar-brand {
  display: block;
  float: none;
  margin: -4px auto;
  padding: 0;
  position: relative;
  height: 58px;
  width: 58px;
  z-index: 1;

  @media (min-width: 768px) {
    float: none;
    height: 100px;
    width: 100px;
    margin-top: 0;
    margin-bottom: -50px;
  }

  @media (min-width: 992px) {
    height: 150px;
    width: 150px;
    margin-bottom: -75px;
  }

  #brand-logo {
    display: inline-block;
    background: url('../images/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;

    position: absolute;
  }
}

.navbar {
  margin-bottom: 0;

  .container .navbar-brand,
  .container-fluid .navbar-brand {
    margin-left: auto;
  }
}

.navbar-default {
  background-color: #fdd311;
  border: none;
  border-radius: 0;

  .navbar-toggle {
    border-color: #000;
    position: relative;
    height: 34px;

    @media (min-width: 992px) {
      height: 49px;
    }

    &:hover,
    &:focus {
      background-color: #fddd39;
    }

    .icon-bar {
      background-color: #222;

      &:nth-child(2),
      &:nth-child(4) {
        position: absolute;
        //transition-duration: 0.3s, 0.3s;
        //transition-delay: 0.3s, 0s;
      }

      // First bar
      &:nth-child(2) {
        position: absolute;
        top: 15px;

        @media (min-width: 992px) {
          top: 21px;
        }
        transform: rotate(45deg);
      }

      // Second bar
      &:nth-child(3) {
        background-color: transparent;
      }

      // Third bar
      &:nth-child(4) {
        position: absolute;
        bottom: 15px;
        @media (min-width: 992px) {
          bottom: 21px;
        }
        transform: rotate(-45deg);
      }
    }

    &.collapsed {
      .icon-bar {

        &:nth-child(2),
        &:nth-child(4) {
          position: relative;
        }

        // First bar
        &:nth-child(2) {
          top: 0;
          transform: rotate(0deg);
        }

        &:nth-child(3) {
          background-color: #222;
          //transition: background 0s 0.3s;
        }

        // Third bar
        &:nth-child(4) {
          bottom: 0;
          transform: rotate(0deg);
        }
      }
    }
  }

  .navbar-text {
    color: $text-color;
  }
}

.nav > li > {
  a {
    border-radius: 4px;
  }
  a:hover,
  a:focus {
    text-decoration: none;
    background-color: #fddd39;
  }
}

.navbar-clear {
  background-color: transparent;
}

.call-today {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  margin-right: 0;
  @media (min-width: 992px) {
    font-size: 27px;
  }
}

#main-nav-collapse .nav {
  padding-bottom: 14px;
}

#page-wrapper {
  background-color: #fff;
  margin-top: 50px;

  @media (min-width: 768px) {
    padding-bottom: 40px;
  }

  @media (min-width: 992px) {
    margin-top: 75px;
  }

  &.no-margin {
    margin: 0;
  }

  &.no-padding {
    padding: 0;
  }

  &.has-bg-img {
    padding-top: 0;

    .bg-img {
      background-position: center;
      background-size: cover;
      padding-top: 75px;

      @media (min-width: 768px) {
        padding-top: 150px;
      }

      @media (min-width: 992px) {
        padding-top: 180px;
      }

      .img-title-wrapper {
        padding: 15px;
        a {
          color: #DE0101;
        }
      }

      h1 {
        margin: 0;
      }
    }
  }
}

#content {
  margin-top: 50px;

  .column, .main {
    header:first-of-type {
      h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
      }
    }
  }
}

.full-panel {
  height: 100%;
  height: 100vh;
  width: 100%;
}

#slide-welcome {
  height: 100%;

  .slide__bg {
    background-image: url(../images/inside-shop-back-1680x1121.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
  }
}

#slide-about-background {
  background-color: #fff;
  padding: 40px 0;
  z-index: 20;
}

.bg-white-transparent {
  background-image: url('../images/black-bg-transparent.png');
  color: #fff;
}



.front-card {
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  text-align: center;

  h1 {
    margin-top: 0;
    margin-bottom: 25px;
  }

  p {
    text-align: center;
  }
}

.full-panel-hover-card {
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  text-align: center;

  h1 {
    margin-top: 0;
    margin-bottom: 25px;
  }

  p {
    text-align: center;
  }
}

.brand-logo {
  background-image: url('../images/logo.png');
  background-size: cover;
}

h4.modal-title .brand-logo {
  display: block;
  float: left;
  height: 70px;
  width: 70px;
  margin: -21px 10px -21px -21px;
  position: relative;
  z-index: 1051;
}

#footer {
  background-color: #000000;
  color: #ddd;
  padding: 20px 0;
}

.modal-content {
  background-color: $accurate-yellow;
}

.modal-body {
  background-color: #fff;
}

blockquote.review {
  border-color: #DE0101;
  
  h3 {
    margin-top: 0;
  }
  
  .review-title {
    display: block;
    margin-top: 11px;
  }
}

.rating  {
  .fa-star-o {
    color: #999999;
  }

  .fa-star {
    color: $accurate-yellow;
  }
}

.map-wrapper {
  height: 100vh;
  position: relative;

  .map {
    position: absolute;
    height: 400px;
    width: 100%;
  }

  .map-title-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }
}

.line {
  display: inline-block;
}
